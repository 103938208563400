import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import LinkDown from "src/atoms/Vectors/Links/LinkDown";
import { icons, outline } from "src/styles/variables";

export const ALIGN = {
  left: "left",
  right: "right",
};

const Dropdown = React.memo(({ children, align, ...rest }) => (
  <Outer align={align}>
    <Icon size={icons.xs} />
    <Select {...rest}>{children}</Select>
  </Outer>
));

Dropdown.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf([ALIGN.left, ALIGN.right]),
};

const Outer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) =>
    ({ [ALIGN.left]: "flex-start", [ALIGN.right]: "flex-end" }[align] ||
    "flex-start")};
`;

const Icon = styled(LinkDown)`
  position: absolute;
  z-index: 0;
  right: 0.2em;
  margin-top: 0.2em;
  width: 1em;
`;

const Select = styled.select`
  position: relative;
  left: 0;
  z-index: 1;
  padding-left: 0.2em;
  padding-right: calc(${icons.xs} + 0.2em);
  appearance: none;
  border: 0 none;
  background: transparent;
  color: inherit;
  font: inherit;

  &:focus {
    outline: none;
    box-shadow: ${outline};
  }
`;

export default Dropdown;
