import PropTypes from "prop-types";

export const Category = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export const Subcategory = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  flavors: PropTypes.array,
};
