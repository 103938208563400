import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ListItem from "./ListItem";

const Push = ({
  category,
  onClick,
  hasInvertedSizes,
  title,
  subtitle,
  image,
  ...rest
}) => (
  <ListItem
    as={ListItemStyled}
    title={title || category.title}
    subtitle={subtitle || category.subtitle}
    image={image || category.image}
    isPush
    hasInvertedSizes={hasInvertedSizes}
    onClick={onClick}
    {...rest}
  />
);

Push.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  hasInvertedSizes: PropTypes.bool,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

const ListItemStyled = styled.a`
  display: block;
`;

export default Push;
