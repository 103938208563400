import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, outline, spacing } from "src/styles/variables";
import { SubHeaderS } from "src/atoms/Typography";

const TogglePills = ({ className, items, onChange = (v) => v }) => {
  const [active, setActive] = useState();

  const selectItem = (pill, key) => {
    setActive(key);
    onChange(pill, key);
  };

  return (
    <Outer className={className}>
      {items.map((item, key) => (
        <Pill
          key={key}
          as="a"
          active={active !== undefined ? active === key : item.active}
          onClick={(e) => {
            e.preventDefault();
            selectItem(item, key);
          }}
          href={item.slug}
        >
          {item.text}
        </Pill>
      ))}
    </Outer>
  );
};

TogglePills.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      active: PropTypes.bool.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Outer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Pill = styled(SubHeaderS)`
  color: ${({ active }) => (active ? colors.black : colors.darkGrey)};
  background: ${({ active }) => (active ? colors.lighterGrey : "transparent")};
  transition: background-color 0.25s ease;
  border-radius: 26px;
  padding: 10px 40px;
  text-decoration: none;
  margin: ${spacing.stack.xs};
  height: 40px;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: ${outline};
  }
`;

export default TogglePills;
