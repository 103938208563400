import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Ingredient from "src/atoms/Ingredient";

const IngredientList = React.memo(({ className, ingredients, align }) => (
  <Outer className={className} align={align}>
    {(ingredients || []).map((ingredient, key) => (
      <Ingredient key={key} align={align} {...ingredient} />
    ))}
  </Outer>
));

IngredientList.propTypes = {
  className: PropTypes.string,
  ingredients: PropTypes.arrayOf(PropTypes.shape(Ingredient.propTypes)),
  align: PropTypes.string,
};

const Outer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ align }) =>
    align === "left" ? "flex-start" : "space-around"};
  flex-wrap: wrap;
`;

export default IngredientList;
