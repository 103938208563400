import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      d="M6 4L10 8L6 12"
      stroke="#02BA44"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
));
