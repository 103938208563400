import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "src/styles/variables";
import { LabelS } from "src/atoms/Typography";

const Table = React.memo(
  ({ headers, values, cellPadding = "1em", fullWidth = false, ...rest }) => (
    <StyledTable {...rest} fullWidth={fullWidth}>
      <thead>
        <HeaderRow>
          {headers.map((header, key) => (
            <Header as="th" first={key === 0} key={key} padding={cellPadding}>
              {header}
            </Header>
          ))}
        </HeaderRow>
      </thead>
      <tbody>
        {values.map((row, rowKey) => (
          <Row key={rowKey}>
            {row.map((cell, cellKey) => (
              <Cell
                as="td"
                first={cellKey === 0}
                key={cellKey}
                padding={cellPadding}
              >
                {cell}
              </Cell>
            ))}
          </Row>
        ))}
      </tbody>
    </StyledTable>
  )
);

Table.propTypes = {
  cellPadding: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.node).isRequired,
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  fullWidth: PropTypes.bool,
};

const StyledTable = styled.table`
  width: ${({ fullWidth }) => fullWidth && "100%"};
`;

const Row = styled.tr`
  border-bottom: 1px solid ${colors.lightGrey};
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px solid ${colors.activiaGreen};
`;

const Cell = styled(LabelS)`
  padding: ${({ padding }) => padding};
  color: ${colors.darkGrey};
  text-align: ${({ first }) => (first ? "left" : "right")};
`;

const Header = styled(Cell)``;

export default Table;
