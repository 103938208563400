import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { trackRetail, trackAmazonRetail } from "./trackEvents";
import Accordion from "src/atoms/Accordion";
import Plus from "src/atoms/Vectors/Links/Plus";
import Minus from "src/atoms/Vectors/Links/Minus";
import Picture, { FITS, TYPES } from "src/atoms/Picture";
import { spacing, icons, colors } from "src/styles/variables";

const RetailerAccordion = ({ buttonText, retailers }) => {
  return (
    <Container>
      <Accordion
        initial
        hiddenLabel={
          <>
            <AlignedPlus size={icons.xs} /> {buttonText}
          </>
        }
        visibleLabel={
          <>
            <AlignedMinus size={icons.xs} /> {buttonText}
          </>
        }
      >
        <Inner>
          {retailers.map(({ name, url, logo }) => (
            <Link
              href={url}
              target="_blank"
              key={name}
              onClick={(e) => {
                e.preventDefault();
                trackRetail(name, url);
                if (name === "Amazon Fresh") {
                  trackAmazonRetail();
                }
                window.open(url, "_blank");
              }}
            >
              <Picture
                type={TYPES.fixed}
                fit={FITS.contain}
                small={logo}
                alt={name}
              />
            </Link>
          ))}
        </Inner>
      </Accordion>
    </Container>
  );
};
RetailerAccordion.propTypes = {
  buttonText: PropTypes.string,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired,
    })
  ),
};

const Container = styled.div`
  margin: ${spacing.default.lg} 0 ${spacing.default.lg} 0;
`;

const Inner = styled.div`
  padding: ${spacing.default.md} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: ${spacing.stack.xl};
`;

const AlignedPlus = styled(Plus)`
  transform: translateY(1px);
`;

const AlignedMinus = styled(Minus)`
  transform: translateY(1px);
`;

const Link = styled.a`
  width: 112px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0 6px 10px 2px ${colors.translucidBlack};
  margin: ${spacing.default.sm} ${spacing.default.xs};
  padding: ${spacing.default.sm};
`;

export default RetailerAccordion;
