import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Picture, { FITS, TYPES } from "src/atoms/Picture";
import { LabelS } from "src/atoms/Typography";
import { spacing } from "src/styles/variables";

const Ingredient = React.memo(({ image, label, align }) => (
  <Outer align={align}>
    <ImageContainer>
      <Picture type={TYPES.fixed} fit={FITS.contain} small={image} />
    </ImageContainer>
    <LabelS>{label}</LabelS>
  </Outer>
));

Ingredient.propTypes = {
  image: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  align: PropTypes.string,
};

const Outer = styled.div`
  width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${({ align }) =>
    align === "left" &&
    css`
      margin-right: ${spacing.default.sm};
    `}
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 85px;
  margin-bottom: 15px;
`;

export default Ingredient;
