export const trackCategoryPush = (name, flavorTitle) => {
  if (typeof window.tC.event.select_content === "function") {
    window.tC.event.select_content(this, {
      product_category: name,
      content_type: flavorTitle,
    });
  }
};

// export const trackNutritionalInteraction = (name) => {
//   const { productName } = window.tc_vars;
//   if (typeof window.tC.event.nutricionalInfo === "function") {
//     window.tC.event.nutricionalInfo(this, {
//       productName,
//     });
//   }
// };

export const trackProductChange = (name, categoryPush) => {
  const pageName = window.tc_vars.page_name;
  if (typeof window.tC.event.flavour_change === "function") {
    window.tC.event.flavour_change(this, {
      content_type: categoryPush,
      product_name: pageName,
      flavour_name: name,
    });
  }
};

// export const trackBuy = (name) => {
//   const { productName } = window.tc_vars;
//   if (typeof window.tC.event.buyNowButton === "function") {
//     window.tC.event.buyNowButton(this, {
//       productName,
//     });
//   }
// };

export const trackSubcategory = (variantName, productName) => {
  if (typeof window.tC.event.product_variant === "function") {
    window.tC.event.product_variant(this, {
      variant_name: variantName,
      product_name: productName,
    });
    //console.log('window.tC.event.product_variant',window);
  }
};

// export const trackFAQ = () => {
//   const { pageName } = window.tc_vars;
//   if (typeof window.tC.event.qandaClick === "function") {
//     window.tC.event.qandaClick(this, {
//       pageName,
//     });
//   }
// };
