export const trackRetail = (name, url) => {
  const productName = window.tc_vars.product_name;
  if (typeof window.tC.event.retail === "function") {
    window.tC.event.retail(this, {
      product_name: productName,
      retail_name: name,
      retail_link: url,
      // link_url: url,
    });
  }
};

export const trackAmazonRetail = () => {
  if (typeof window.tC.event.amazon === "function") {
    window.tC.event.amazon(this, {});
  }
};
