/**
 * This component handles almost all the different Ecommerce providers for the Activia Product Flavor Page.
 *
 * Possible providers:
 * - Swaven (based on EAN number)
 * - Mikmak (based on EAN number)
 * - Destini (based on Destini code)
 * - Adimo (based on Adimo code and widget)
 *
 * The last ecommerce option is native retailer list. However, this is embedded in a different position as the
 * Ecommerce button, so it is not handled by this component
 */

import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import Button, { SIZES } from "src/atoms/Button";
import styled, { css } from "styled-components";
import Helmet from "react-helmet";

import { spacing, mediaquery } from "src/styles/variables";
// import { trackBuy } from "./trackEvents";

const StyledButton = styled(Button)`
  ${mediaquery.md(css`
    width: auto;
  `)}

  margin: 0 auto ${spacing.default.md} auto;

  &.swn-tag-wtb-btn,
  &.mikmak-tag-wtb-btn,
  &.channelsight-tag-wtb-btn {
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -100;
    margin: 0;
  }
`;

const EcommerceButton = ({
  showEcom,
  destiniAssociationCode,
  retailerList,
  flavorTitle,
  ecomText,
  eanNumber,
  platformToUse,
  channelSightAssetId,
  mikmakAssetId,
  mikmakWidgetId,
  showDestiniWidget,
  adimoTouchpointId,
  adimoWidget,
}) => {
  const [adimoLoaded, setAdimoLoaded] = useState(false);
  const [adimoAvailable, setAdimoAvailable] = useState(false);

  // Append adimo lightbox  script  initialization on useEffect as it needs window
  useEffect(() => {
    if (window && showEcom && ecomText && !retailerList.length) {
      if (adimoTouchpointId && adimoWidget !== "site-product-first") {
        const script = document && document.createElement("script");
        if (!adimoAvailable) {
          if (!window.Adimo.main && !adimoLoaded) {
            const head =
              document &&
              (document.head || document.getElementsByTagName("head")[0]);
            script.src = "https://cdn.adimo.co/scripts/lightboxv2.min.js";
            head.appendChild(script);
            setAdimoLoaded(true);
          }
          setAdimoAvailable(true);
        } else if (!window.Adimo) {
          setAdimoAvailable(false);
        } else {
          window.Adimo.main.init("https://basketads.adimo.co", false);
        }
      }

      if (eanNumber && platformToUse === "ChannelSight") {
        if (document.getElementById("cswidgetjs")) {
          document.getElementById("cswidgetjs").remove();
        }
        if (document.getElementById("cswidgetjsloader")) {
          document.getElementById("cswidgetjsloader").remove();
        }
        const script = document && document.createElement("script");
        const head =
          document &&
          (document.head || document.getElementsByTagName("head")[0]);
        // script.id = "cswidgetjs";
        script.defer = true;
        script.id = "cswidgetjsloader";
        script.src =
          "https://cscoreproweustor.blob.core.windows.net/widget/scripts/cswidget.loader.js";
        head.appendChild(script);
      }

      if (eanNumber && platformToUse === "Mikmak") {
        /* eslint-disable */
        /* Start of Mikmak tag */
        (function (e, d) {
          try {
            var a = (window.swnDataLayer = window.swnDataLayer || {});
            (a.appId = e || a.appId),
              (a.eventBuffer = a.eventBuffer || []),
              (a.loadBuffer = a.loadBuffer || []),
              (a.push =
                a.push ||
                function (e) {
                  a.eventBuffer.push(e);
                }),
              (a.load =
                a.load ||
                function (e) {
                  a.loadBuffer.push(e);
                }),
              (a.dnt = a.dnt != null ? a.dnt : d);
            var t = document.getElementsByTagName("script")[0],
              n = document.createElement("script");
            (n.async = !0),
              (n.src =
                "//wtb-tag.mikmak.ai/scripts/" + a.appId + "/tag.min.js"),
              t.parentNode.insertBefore(n, t);
          } catch (e) {
            console.log(e);
          }
        })(mikmakAssetId, false);
        /* End of Mikmak tag */
      }
    }
  }, [adimoLoaded, adimoAvailable, eanNumber]);

  // No ecom button or retailer list
  if ((!showEcom && !ecomText) || retailerList.length) {
    return null;
  }

  // Swaven - Mikmak - ChannelSight
  if (eanNumber) {
    if (platformToUse === "ChannelSight") {
      return (
        <>
          <StyledButton
            size={SIZES.medium}
            onClick={() => {
              // trackBuy(flavorTitle);
              document.querySelector(".channelsight-tag-wtb-btn").click();
            }}
          >
            {ecomText}
          </StyledButton>
          <StyledButton
            className="channelsight-tag-wtb-btn cswidget"
            size={SIZES.medium}
            data-asset-id={channelSightAssetId}
            data-product-sku={eanNumber}
          >
            {ecomText}
          </StyledButton>
        </>
      );
    } else if (platformToUse === "Mikmak") {
      return (
        <>
          <StyledButton
            size={SIZES.medium}
            onClick={() => {
              // trackBuy(flavorTitle);
              document.querySelector(".mikmak-tag-wtb-btn").click();
            }}
          >
            {ecomText}
          </StyledButton>
          <StyledButton
            className="mikmak-tag-wtb-btn"
            size={SIZES.medium}
            data-mm-wtbid={mikmakWidgetId}
            data-mm-ids={eanNumber}
          >
            {ecomText}
          </StyledButton>
        </>
      );
    } else {
      return (
        <>
          <StyledButton
            size={SIZES.medium}
            onClick={() => {
              // trackBuy(flavorTitle);
              document.querySelector(".swn-tag-wtb-btn").click();
            }}
          >
            {ecomText}
          </StyledButton>
          <StyledButton
            className="swn-tag-wtb-btn"
            size={SIZES.medium}
            data-eans={eanNumber}
          >
            {ecomText}
          </StyledButton>
        </>
      );
    }
  }

  if (destiniAssociationCode) {
    return (
      <StyledButton
        size={SIZES.medium}
        onClick={() => {
          // trackBuy(flavorTitle);
          showDestiniWidget();
        }}
      >
        {ecomText}
      </StyledButton>
    );
  }

  // Adimo
  if (adimoTouchpointId) {
    // Price First
    if (adimoWidget === "site-product-first") {
      return (
        <>
          <Helmet>
            <script src="https://cdn.adimo.co/scripts/priceFirst.min.js" />
          </Helmet>
          <StyledButton
            size={SIZES.medium}
            onClick={() => {
              // trackBuy(flavorTitle);
            }}
            className="adimo-site-product-first"
            data-type="site-product-first"
            data-static-touchpoint-identifier={adimoTouchpointId}
          >
            {ecomText}
          </StyledButton>
        </>
      );
    }

    // Lightbox
    return (
      <>
        <StyledButton
          size={SIZES.medium}
          onClick={() => {
            // trackBuy(flavorTitle);
          }}
          className="adimoWidget"
          data-type="lightbox"
          data-static-touchpoint-identifier={adimoTouchpointId}
        >
          {ecomText}
        </StyledButton>
      </>
    );
  }

  return null;
};

EcommerceButton.propTypes = {
  showEcom: PropTypes.bool,
  destiniAssociationCode: PropTypes.string,
  retailerList: PropTypes.array,
  flavorTitle: PropTypes.string.isRequired,
  ecomText: PropTypes.string,
  eanNumber: PropTypes.string,
  platformToUse: PropTypes.string,
  channelSightAssetId: PropTypes.string,
  mikmakAssetId: PropTypes.string,
  mikmakWidgetId: PropTypes.string,
  showDestiniWidget: PropTypes.func,
  adimoTouchpointId: PropTypes.string,
  adimoWidget: PropTypes.string,
};

export default memo(EcommerceButton);
