import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Settings from "src/stores/Settings";
import Dropdown, { ALIGN } from "src/atoms/Dropdown";
import Table from "src/atoms/Table";
import { icons } from "src/styles/variables";

const NutritionalTable = ({ values, ...rest }) => {
  const [column, setColumn] = useState("per100Table");
  const { rows } = values;
  const per100Table = rows
    .map(({ name, per100 }) => ({
      name,
      value: per100,
    }))
    .filter((v) => v.value);
  const perServingTable = rows
    .map(({ name, perServing }) => ({
      name,
      value: perServing,
    }))
    .filter((v) => v.value);
  const rdaTable = rows
    .map(({ name, rda }) => ({
      name,
      value: rda,
    }))
    .filter((v) => v.value);

  const cleanValues = { per100Table, perServingTable, rdaTable };

  const updateColumn = (e) => {
    setColumn(e.target.value);
  };

  if (!per100Table.length && !perServingTable.length && !rdaTable.length) {
    return null;
  }

  return (
    <Settings.Consumer>
      {(settings) => (
        <Table
          {...rest}
          fullWidth
          cellPadding="1em 0"
          headers={[
            settings.translations.nutritionalInformation,
            <Dropdown
              onChange={updateColumn}
              align={ALIGN.right}
              role="presentation"
            >
              {per100Table.length && (
                <option value="per100Table">
                  {settings.translations.per100g}
                </option>
              )}
              {perServingTable.length && (
                <option value="perServingTable">
                  {settings.translations.perServing}
                </option>
              )}
              {rdaTable.length && (
                <option value="rdaTable">
                  {settings.translations.percentRDA}
                </option>
              )}
            </Dropdown>,
          ]}
          values={(cleanValues[column] || []).map((row) => [
            row.name,
            <PaddedValue>{row.value}</PaddedValue>,
          ])}
        />
      )}
    </Settings.Consumer>
  );
};

NutritionalTable.propTypes = {
  values: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};

const PaddedValue = styled.div`
  padding-right: ${icons.xs}; /* the size of the icon in the dropdown */
`;

export default NutritionalTable;
