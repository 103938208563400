import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ChevronLeft from "src/atoms/Vectors/Links/LinkLeft";
import ChevronRight from "src/atoms/Vectors/Links/LinkRight";

import { colors, icons } from "src/styles/variables";
import { LabelM } from "src/atoms/Typography";
import NutritionalTable from "src/molecules/NutritionalTable";

const NutritionalTableCarousel = ({ tables }) => {
  if (tables.length === 1) {
    return <NutritionalTable values={tables[0]} />;
  }

  const [currentTable, setCurrentTable] = useState(0);
  const [slideSide, setSlideSide] = useState("right");
  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => {
      showPrevTable();
    },
    onSwipedRight: (e) => {
      showNextTable();
    },
  });

  const showNextTable = () => {
    setSlideSide("right");
    setTimeout(() => {
      if (currentTable === tables.length - 1) {
        setCurrentTable(0);
      } else {
        setCurrentTable(currentTable + 1);
      }
    }, 0);
  };

  const showPrevTable = () => {
    setSlideSide("left");
    setTimeout(() => {
      if (currentTable === 0) {
        setCurrentTable(tables.length - 1);
      } else {
        setCurrentTable(currentTable - 1);
      }
    }, 0);
  };

  return (
    <div {...swipeHandlers}>
      <Wrapper>
        <LeftButton onClick={showPrevTable}>
          <ChevronLeft size={icons.xs} />
        </LeftButton>
        <RightButton onClick={showNextTable}>
          <ChevronRight size={icons.xs} />
        </RightButton>
        <Wrapper>
          <TransitionGroup>
            <CSSTransition key={currentTable} timeout={500} classNames="slide">
              <TransitionWrapper direction={slideSide}>
                <TableTitle as="div">{tables[currentTable].title}</TableTitle>
                <StyledNutritionalTable values={tables[currentTable]} />
              </TransitionWrapper>
            </CSSTransition>
          </TransitionGroup>
        </Wrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const TransitionWrapper = styled.div`
  width: 100%;

  &.slide-enter {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(
      ${({ direction }) => (direction === "left" ? "100%" : "-100%")}
    );
    opacity: 0;
  }

  &.slide-enter-active {
    transition: all 0.5s linear;
    transform: translateX(0);
    opacity: 1;
  }
  &.slide-exit {
    transform: translateX(0);
    opacity: 1;
  }
  &.slide-exit-active {
    transition: all 0.5s linear;
    transform: translateX(
      ${({ direction }) => (direction === "left" ? "-100%" : "100%")}
    );
    opacity: 0;
  }
`;

const StyledNutritionalTable = styled(NutritionalTable)`
  tbody tr:first-child td {
    padding-top: 50px;
  }
`;

const TableTitle = styled(LabelM)`
  color: ${colors.activiaGreen};
  width: 100%;
  text-align: center;
  position: absolute;
  top: 65px;
`;

const LeftButton = styled.button`
  left: 0;
  position: absolute;
  top: 70px;
  z-index: 1;
  width: 20px;
  cursor: pointer;
`;

const RightButton = styled(LeftButton)`
  left: auto;
  right: 0;
`;

NutritionalTableCarousel.propTypes = {
  tables: PropTypes.arrayOf(PropTypes.shape(NutritionalTable.propTypes)),
};

export default NutritionalTableCarousel;
