import React from "react";
import PropTypes from "prop-types";

import Settings from "src/stores/Settings";
import { Subcategory as SubcategoryShape } from "./types";
import TogglePills from "src/atoms/TogglePills";

const Subcategories = ({ items, active, ...rest }) => (
  <Settings.Consumer>
    {({ locale }) => (
      <TogglePills
        items={items.map((item) => ({
          ...item,
          slug: item.slug,
          text: item.name,
        }))}
        {...rest}
      />
    )}
  </Settings.Consumer>
);

Subcategories.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SubcategoryShape)),
  active: PropTypes.shape(SubcategoryShape),
};

export default Subcategories;
