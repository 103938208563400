import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, spacing } from "src/styles/variables";
import { LabelS, LabelM } from "src/atoms/Typography";

const DetailList = React.memo(({ className, details }) => (
  <table className={className}>
    <tbody>
      {(details || []).map(([label, value], key) => (
        <tr key={key}>
          <LabelCell as="td">{label}</LabelCell>
          <ValueCell as="td">{value}</ValueCell>
        </tr>
      ))}
    </tbody>
  </table>
));

DetailList.propTypes = {
  className: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

const LabelCell = styled(LabelM)`
  color: ${colors.darkGrey};
  padding-right: ${spacing.default.xs};
  padding-bottom: ${spacing.default.xs};
  white-space: nowrap;
`;
const ValueCell = styled(LabelS)`
  color: ${colors.darkGrey};
`;

export default DetailList;
